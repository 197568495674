<!-- eslint-disable vue/no-v-html -->
<template>
  <MentionCard
    :title="mention.title"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :options="options"
    :no-highlighting="noHighlighting"
    :always-show-keywords="alwaysShowKeywords"
    :show-checkbox="showCheckbox"
    :selected="selected"
    :class="mentionCardClass"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #header>
      <span
        v-if="mention.syndications && mention.syndications.length > 0"
        @click.stop="displaySyndication = !displaySyndication"
      >
        {{ mention.source.name }} + {{ mention.syndications.length }}
      </span>
      <span v-else>
        {{ mention.source.name }}
      </span>
      <span class="softer">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span>
          {{
            $t("magazine_mention_card.word_count", {
              wordCount: mention.word_count,
              pageNumbers: formatPageNumbers(mention.page_numbers, true),
            })
          }}
        </span>
      </span>
    </template>

    <template #beforeBody>
      <div>
        <!-- Display syndications -->
        <div
          v-if="displaySyndication"
          class="publishers-list"
        >
          <a
            v-for="(syndicated, key) in mention.syndications"
            :key="key"
            class="no-shrink"
            @click.stop="syndicationClicked(syndicated)"
          >
            <span class="bold">
              {{ syndicated.source_name }}
            </span>
            <span
              style
              class="muted pull-right syndication-time"
            >
              {{ formatDate(syndicated.published_at, "h:mmaaa") }}
            </span>
          </a>
        </div>
      </div>
    </template>

    <template #afterBody>
      <div class="row soft q-mt-md items-center q-gutter-x-sm">
        <PotentialAudienceReachWidget :mention="mention" />
        <AdvertisingValueWidget :mention="mention" />
        <SentimentWidget
          :mention="mention"
          :stream="stream"
        />
        <ReactScoreWidget
          class="soft"
          :mention="mention"
          is-parent-visible
        />
      </div>
    </template>

    <slot />
  </MentionCard>
</template>

<script>
import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { MentionCard, props } from "shared/components/MentionCard";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import { formatPageNumbers } from "shared/helpers/array";
import { formatDate } from "shared/helpers/date";

export default {
  name: "MagazineMentionCard",
  components: {
    MentionCard,
    AuthorWidget,
    AdvertisingValueWidget,
    PotentialAudienceReachWidget,
    SentimentWidget,
    ReactScoreWidget,
  },
  props,
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { isMentionNotClickable, mentionClicked } = useMention(
      mentionProps,
      context
    );

    return {
      isMentionNotClickable,
      mentionClicked,
    };
  },
  data() {
    return {
      displaySyndication: false,
    };
  },
  computed: {
    mentionCardClass() {
      if (this.isMentionNotClickable(this.mention)) return null;

      return "pointer";
    },
  },
  methods: {
    formatDate,
    formatPageNumbers,
    syndicationClicked(syndication) {
      this.mentionClicked({ ...syndication, type: "article" });
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>
